<template>
	<div class="home">
		<div class="home_top">
			<div style="width: 100%; height: 300px">
				<div v-if="img_url">
					<img class="home_top_img" v-if="img_url == '../../assets/bg.svg'" src="../../assets/bg.svg"
						type="image/svg+xml" />
					<img class="home_top_img" v-else :src="img_url" type="image/svg+xml" />
				</div>
			</div>
			<div :style="!check_root.title ? 'margin-top: -290px' : ''" class="home_top_search">
				<div class="home_top_p_div"> {{ check_root.title || '' }} </div>
				<div :style="!check_root.title ? 'height: 70px' : ''" class="home_top_search_box">
					<div class="home_top_search_box_div">
						<el-input v-model="file_name" size="large" placeholder="请输入文件名称查询"> </el-input>
						<el-button @click="search_btn" type="primary">搜 索</el-button>
					</div>
				</div>
			</div>
		</div>
		<div class="home_bot">
			<div v-if="file_folder.length != 0" class="home_bot_one">
				<div class="home_bot_l">
					<el-menu style="height: 100%" default-active="1001" class="el-menu-vertical-demo">
						<el-menu-item @click="cut_btn({ name: '全部', id: -1 })" class="sub-menu" index="1001">
							<el-icon><icon-menu /></el-icon>
							<span>全部</span>
						</el-menu-item>
						<template v-for="(e, i) in file_folder" :key="i">
							<el-sub-menu @click="cut_btn(e)" v-if="e.childlist.length != 0" class="sub-menu" :index="i">
								<template #title>
									<el-icon>
										<location />
									</el-icon>
									<span>{{ e.name }}</span>
								</template>
								<el-menu-item-group title="">
									<template v-for="(e1, i1) in e.childlist" :key="i1">
										<el-sub-menu @click="cut_btn(e1)" v-if="e1.childlist.length != 0"
											:index="i + '-' + i1">
											<template #title>
												<el-icon style="width: 35px">
													<location />
												</el-icon>
												<span>{{ e1.name }}</span>
											</template>
											<!-- <el-menu-item index="1-4-1">浙江模具8</el-menu-item>
											<el-sub-menu index="1-4-2">
												<template #title>
													<el-icon style="width: 55px"><location /></el-icon>
													<span>浙江模具</span>
												</template>
												<el-menu-item index="1-4-2-1">浙江模具8</el-menu-item>
											</el-sub-menu> -->
										</el-sub-menu>
										<el-menu-item @click="cut_btn(e1)" v-else :index="i + '-' + i1">{{ e1.name
											}}</el-menu-item>
									</template>
								</el-menu-item-group>
							</el-sub-menu>
							<el-menu-item @click="cut_btn(e)" v-else class="sub-menu" :index="i">
								<el-icon><icon-menu /></el-icon>
								<span>{{ e.name }}</span>
							</el-menu-item>
						</template>
						<el-menu-item @click="cut_btn({ name: '其他', id: 0 })" class="sub-menu" index="1000">
							<el-icon><icon-menu /></el-icon>
							<span>其他</span>
						</el-menu-item>
					</el-menu>
				</div>
				<div class="home_bot_r">
					<p>{{ file_text }}</p>
					<div class="bot_r_box1">
						<div class="r_box1_t">
							<span>文件名称</span>
							<span>操作</span>
						</div>
						<div class="r_box1_b" v-for="(e, i) in file_list_data.list">
							<span>{{ e.title }}</span>
							<div>
								<span @click="preview_download_btn(1, e)">预览</span>
								<el-divider v-if="check_root.download_type != 4" direction="vertical"></el-divider>
								<span v-if="check_root.download_type != 4" @click="preview_download_btn(2, e)">下载</span>
							</div>
						</div>
						<el-empty v-if="file_list_data.total == 0" style="margin-top: 80px"
							description="暂无数据"></el-empty>
					</div>
					<div v-if="file_list_data.total != 0 && !(file_list_data.total <= 8)" class="bot_r_pagination">
						<el-pagination :page-size="8" @current-change="current_change" background
							layout="prev, pager, next" :total="file_list_data.total"> </el-pagination>
					</div>
				</div>
			</div>
			<div v-else class="home_bot_one">
				<div class="home_bot_r">
					<div class="bot_r_box1 bot_r_box1_1">
						<div class="r_box1_t">
							<span>文件名称</span>
							<span>操作</span>
						</div>
						<div class="r_box1_b" v-for="(e, i) in file_list_data.list">
							<span>{{ e.title }}</span>
							<div>
								<span @click="preview_download_btn(1, e)">预览</span>
								<el-divider v-if="check_root.download_type != 4" direction="vertical"></el-divider>
								<span v-if="check_root.download_type != 4" @click="preview_download_btn(2, e)">下载</span>
							</div>
						</div>
						<el-empty v-if="file_list_data.total == 0" style="margin-top: 80px"
							description="暂无数据"></el-empty>
					</div>
					<div v-if="file_list_data.total != 0 && !(file_list_data.total <= 8)"
						style="justify-content: center" class="bot_r_pagination">
						<el-pagination :page-size="8" @current-change="current_change" background
							layout="prev, pager, next" :total="file_list_data.total"> </el-pagination>
					</div>
				</div>
			</div>
		</div>
		<el-dialog v-model="permissiondialogVisible" title="" top="20vh" width="500px" custom-class="dialog_class"
			:before-close="() => {
					permissiondialogVisible = false;
				}
					">
			<div v-if="common_init" class="permission_dialog_box">
				<img class="info_img" src="../../assets/info.svg" alt="" />
				<p class="permission_p">{{ log_msg }}</p>
				<div class="permission_div1">
					<img src="../../assets/phone.svg" alt="" />
					<span>{{ common_init.config.info.phone }}（ {{ common_init.config.info.user_name }} ）</span>
				</div>
				<div class="permission_div2">
					<el-button @click="permissiondialogVisible = false">我知道了</el-button>
					<!-- <el-button type="primary">立即入会</el-button> -->
				</div>
			</div>
		</el-dialog>
		<el-dialog v-model="logindialogVisible" title="" top="20vh" custom-class="dialog_class" width="500px"
			:close-on-click-modal="file_list_data ? true : false" :close-on-press-escape="file_list_data ? true : false"
			:show-close="file_list_data ? true : false" :before-close="() => {
					logindialogVisible = false;
				}
					">
			<div v-if="common_init" class="permission_dialog_box">
				<img class="info_img" src="../../assets/info.svg" alt="" />
				<p class="permission_p">{{ log_msg }}</p>
				<!-- <div class="permission_div1">
					<img src="../../assets/phone.svg" alt="" />
					<span>{{ common_init.config.info.phone }}（ {{ common_init.config.info.user_name }} ）</span>
				</div> -->
				<div class="permission_div2">
					<el-button v-if="file_list_data" @click="logindialogVisible = false">我知道了</el-button>
					<el-button @click="login_immediately" type="primary">立即登录</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog v-model="hintVisible" title="" top="20vh" custom-class="dialog_class" width="500px" :before-close="() => {
					hintVisible = false;
				}
					">
			<div class="permission_dialog_box">
				<img class="info_img" src="../../assets/info.svg" alt="" />
				<p class="permission_p">请在外部浏览器中打开下载！</p>
			</div>
		</el-dialog>
		<el-dialog v-model="imgDialogVisible" title="" top="12vh" width="600px" custom-class="dialog_class"
			:before-close="() => {
					imgDialogVisible = false;
				}
					">
			<div style="width: 100%">
				<img style="width: 100%" :src="imgDialogVisibleUrl" alt="" />
			</div>
		</el-dialog>
	</div>
</template>
<script>
export default {
	data () {
		return {
			hintVisible: false,
			file_name: '',
			file_folder: [],
			file_list_data: '',
			file_text: '全部',
			check_root: '',
			page: 1,
			type: -1,
			permissiondialogVisible: false,
			logindialogVisible: false,
			imgDialogVisible: false,
			imgDialogVisibleUrl: '',
			user_record_type: '',
			common_init: '',
			log_msg: '需要登录后才能查看！',
			img_url: '',
		};
	},
	watch: {},
	created () {
		// document.title = '文件下载';
	},
	mounted () {
		this.get_journal_check_root();
		this.get_journal_folder();
		this.get_journal_list();
		this.get_common_init();
		if (localStorage.token) {
			this.get_user_record();
		}
	},
	methods: {
		login_immediately () {
			if (window.parent) {
				window.parent.postMessage(
					{
						cmd: 'login',
						params: {
							status: '0',
						},
					},
					'*'
				);
			}
		},
		async get_common_init () {
			const res = await this.$request('/api/common/init', {}, 'get');
			this.common_init = res.data;
		},
		async get_user_record () {
			try {
				const res = await this.$request('/api/pc/user/record', {}, 'get');
				if (res.code != 0) {
					this.user_record_type = res.data.type;
				}
			} catch (error) { }
		},
		async get_journal_folder () {
			const res = await this.$request('/api/pc/file/folder', { label: 'disk' }, 'get');
			this.file_folder = res.data;
		},
		async get_journal_list () {
			const res = await this.$request(
				'/api/pc/file',
				{
					page: this.page,
					type: this.type,
					search: this.file_name,
					limit: 8,
					label: 'disk',
				},
				'get'
			);
			if (res.code == 1) {
				this.file_list_data = res.data;
			} else {
				if (localStorage.token) {
					this.log_msg = '非常抱歉，该账号没有权限查看，如有需求请联系我们';
					this.permissiondialogVisible = true;
				} else {
					this.log_msg = '需要登录后才能查看！';
					this.logindialogVisible = true;
				}
			}
		},
		async get_journal_check_root () {
			const res = await this.$request(
				'/api/pc/file/check_root',
				{
					label: 'disk',
				},
				'get'
			);
			this.check_root = res.data;
			if (this.check_root.file) {
				this.img_url = this.check_root.file;
			} else {
				this.img_url = '../../assets/bg.svg';
			}
		},
		search_btn () {
			this.page = 1;
			this.type = 0;
			this.get_journal_list();
		},
		cut_btn (e) {
			event.stopPropagation();
			this.file_text = e.name;
			this.file_name = '';
			this.page = 1;
			this.type = e.id;
			this.get_journal_list();
		},
		current_change (e) {
			this.page = e;
			this.get_journal_list();
		},
		async get_journal_data (id) {
			const res = await this.$request('/api/pc/file/info', { id: id, label: 'disk' }, 'get');
			return res.data;
		},
		open_preview (e) {
			let str = e.file.split('.')[e.file.split('.').length - 1];
			if (str.match(/doc|docx|xls|xlsx|ppt|pptx/gi)) {
				let url = encodeURIComponent(e.file);
				let officeUrl = 'http://view.officeapps.live.com/op/view.aspx?src=' + url;
				if (localStorage.is_pc == 1) {
					window.open(officeUrl, '_target');
				} else {
					if (window.parent) {
						window.parent.location.href = officeUrl;
					} else {
						window.location.href = officeUrl;
					}
				}
			} else if (str.match(/txt/gi)) {
				if (localStorage.is_pc == 1) {
					window.open(e.file);
				} else {
					if (window.parent) {
						window.parent.location.href = e.file;
					} else {
						window.location.href = e.file;
					}
				}
			} else if (str.match(/pdf/gi)) {
				let routeUrl = this.$router.resolve({ path: '/journalPreview', query: { store_id: localStorage.store_id, id: e.id, label: 'disk' } });
				if (localStorage.is_pc == 1) {
					window.open(routeUrl.href, '_blank');
				} else {
					if (window.parent) {
						window.parent.location.href = routeUrl.href;
					} else {
						window.location.href = routeUrl.href;
					}
				}
			} else if (str.match(/jpg|jpeg|gif|png|bmp|webp|tiff|heic|psd/gi)) {
				this.imgDialogVisibleUrl = e.file;
				this.imgDialogVisible = true;
			}
		},
		async preview_download_btn (type, q) {
			let e = (await this.get_journal_data(q.id)) || {};
			e.id = q.id;
			if (type == 1) {
				if (this.check_root.show_type == 1) {
					this.open_preview(e);
				} else if (this.check_root.show_type == 2) {
					if (localStorage.token) {
						this.open_preview(e);
					} else {
						this.log_msg = '需要登录后才能查看！';
						this.logindialogVisible = true;
					}
				} else if (this.check_root.show_type == 3) {
					if (localStorage.token) {
						if (this.user_record_type) {
							this.open_preview(e);
						} else {
							this.log_msg = '非常抱歉，该账号没有权限查看，如有需求请联系我们';
							this.permissiondialogVisible = true;
						}
					} else {
						this.log_msg = '需要登录后才能查看！';
						this.logindialogVisible = true;
					}
				}
			} else {
				if (localStorage.is_pc == 0 && localStorage.is_weixin == 1) {
					this.hintVisible = true;
				} else {
					if (this.check_root.download_type == 1) {
						this.handleDown({ url: e.file, fileName: e.title });
					} else if (this.check_root.download_type == 2) {
						if (localStorage.token) {
							this.handleDown({ url: e.file, fileName: e.title });
						} else {
							this.log_msg = '需要登录后才能下载！';
							this.logindialogVisible = true;
						}
					} else if (this.check_root.download_type == 3) {
						if (localStorage.token) {
							if (this.user_record_type) {
								this.handleDown({ url: e.file, fileName: e.title });
							} else {
								this.log_msg = '非常抱歉，该账号没有权限下载，如有需求请联系我们';
								this.permissiondialogVisible = true;
							}
						} else {
							this.log_msg = '需要登录后才能下载！';
							this.logindialogVisible = true;
						}
					}
				}
			}
		},
		handleDown (row) {
			const _that = this; // 解决跨域下载，不能重命名文件名
			this.getBlob(row.url, function (blob) {
				_that.saveAs(blob, row.fileName);
			});
		},
		getBlob (url, cb) {
			var xhr = new XMLHttpRequest();
			xhr.open('GET', url, true);
			xhr.responseType = 'blob';
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response);
				}
			};
			xhr.send();
		},
		saveAs (blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename);
			} else {
				var link = document.createElement('a');
				var body = document.querySelector('body');
				link.href = window.URL.createObjectURL(blob);
				link.download = filename; // fix Firefox
				link.style.display = 'none';
				body.appendChild(link);
				link.click();
				body.removeChild(link);
				window.URL.revokeObjectURL(link.href);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .el-menu-item-group__title {
	padding: 0 0 0 20px;
}

::v-deep .el-sub-menu__title {
	padding: 0 !important;
	padding-left: 0 !important;
}

::v-deep .el-sub-menu .el-menu-item {
	min-width: 100px;
}

::v-deep .el-input--large .el-input__inner {
	border-radius: 4px 0px 0px 4px !important;
}

.permission_dialog_box {
	width: 100%;
	text-align: center;

	.info_img {
		width: 38px;
		height: 38px;
		margin: 20px;
	}

	.permission_p {
		font-size: 15px;
		font-weight: 400;
		color: #303133;
	}

	.permission_div1 {
		margin: 20px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 16px;
			height: 16px;
			margin: 0 10px;
		}

		span {
			font-size: 14px;
			font-weight: 400;
			color: #2d91e3;
		}
	}

	.permission_div2 {
		margin-top: 30px;
	}
}

.home {
	width: 100%;
	background: rgba(248, 248, 248, 0.39);
	min-height: 100vh;

	.home_top {
		width: 100%;

		// min-width: 1440px;
		.home_top_img {
			width: 100%;
			height: 300px;
			border: none;
		}

		.home_top_search {
			width: 600px;
			margin: auto;
			margin-top: -260px;

			.home_top_p_div {
				width: 100%;
				height: 130px;
				display: flex;
				align-items: center;
				color: #ffffff;
				justify-content: center;
				font-size: 42px;
			}

			.home_top_search_box {
				.home_top_search_box_div {
					display: flex;
					align-items: center;

					.el-button {
						width: 125px;
						height: 40px;
						background: linear-gradient(131deg, #66a7ff 0%, #197aff 100%);
						border-radius: 0px 4px 4px 0px;
					}
				}
			}
		}
	}

	.home_bot {
		max-width: 1080px;
		// height: calc(100vh - 280px);
		overflow: auto;
		margin: 55px auto;
		margin-bottom: 0;
		background: #ffffff;

		.home_bot_one {
			width: 100%;
			display: flex;

			.home_bot_l {
				min-width: 180px;

				.sub-menu {
					padding: 0 !important;
					padding-left: 0 !important;
				}
			}

			.home_bot_r {
				width: 100px;
				flex-grow: 1;
				background: #ffffff;

				>p {
					font-size: 16px;
					font-weight: bold;
					color: #303133;
					padding: 15px 30px;
					border-right: 1px solid #ebeef5;
					border-bottom: 1px solid #ebeef5;
				}

				.bot_r_box1 {
					width: 100%;
					min-height: 530px;

					.r_box1_t {
						width: 100%;
						height: 48px;
						background: rgba(245, 247, 250, 0.39);
						display: flex;
						justify-content: space-between;
						align-items: center;
						border-bottom: 1px solid #ebeef5;

						span {
							font-size: 14px;
							font-weight: 400;
							color: #606266;
							display: inline-block;
							width: 200px;
							padding: 0 30px;
						}
					}

					.r_box1_b {
						width: 100%;
						min-height: 56px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						border-bottom: 1px solid #ebeef5;
						padding: 10px 0;

						>span {
							font-size: 14px;
							font-weight: 400;
							color: #303133;
							padding: 0 30px;
						}

						>div {
							width: 200px;
							padding: 0 30px;
							display: flex;
							justify-content: flex-start;
							align-items: center;

							span {
								font-size: 14px;
								font-weight: 500;
								color: #1890ff;
								cursor: pointer;
							}
						}
					}
				}

				.bot_r_pagination {
					width: 100%;
					display: flex;
					justify-content: flex-end;
					padding: 0 30px;
					margin-bottom: 30px;
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.home {
		.home_top {
			min-width: 300px;

			.home_top_img {
				height: 180px;
			}

			.home_top_search {
				width: 80%;
				margin-top: -370px;

				.home_top_p_div {
					height: 200px;
					font-size: 32px;
					padding-top: 60px;
				}

				.home_top_search_box {
					margin-top: -20px;
				}
			}
		}

		.home_bot {
			width: 100%;
			min-width: 300px;
			margin-top: 30px;

			.home_bot_one {
				.home_bot_l {
					width: 0px !important;
					min-width: 95px !important;

					.el-menu {
						width: 95px !important;
					}
				}

				.home_bot_r {
					position: relative;

					.bot_r_box1 {
						min-height: 300px;
						height: calc(100vh - 235px);
						overflow: auto;
						padding-bottom: 80px;

						.r_box1_t {
							span {
								width: 140px;
							}
						}

						.r_box1_b {
							display: flex;
							justify-content: space-between;

							>span {
								flex-grow: 1;
								display: inline-block;
								width: 140px;
								padding-right: 0;
							}

							>div {
								width: 140px;
								padding: 0 30px;
							}
						}
					}

					.bot_r_box1_1 {
						height: calc(100vh - 180px);
					}

					.bot_r_pagination {
						background: #ffffff;
						position: absolute;
						bottom: -30px;
						padding: 5px;
						padding-bottom: 20px;
					}
				}
			}
		}
	}
}
</style>
<style>
.dialog_class {
	width: 80% !important;
	max-width: 500px !important;
}
</style>
